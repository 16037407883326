<template>
  <v-card>
    <v-card-title>{{
      isEditMode ? "Edit Delivery" : "New Delivery"
    }}</v-card-title>
    <v-card-subtitle>
      {{ product ? product.name : "Product N/A" }}
    </v-card-subtitle>
    <v-card-text>
      <v-autocomplete
        v-if="customers"
        style="z-index: 100"
        clearable
        label="Customer"
        v-model="delivery.customerId"
        :items="customers"
        item-text="name"
        item-value="id"
      />
      <v-text-field
        label="Quantity"
        v-model="delivery.quantity"
        type="number"
      ></v-text-field>
      <date-picker
        :label="$vuetify.lang.t('$vuetify.base.startDate')"
        :defaultNowOnNull="false"
        v-model="delivery.start"
      />
      <date-picker
        :label="$vuetify.lang.t('$vuetify.base.endDate')"
        :defaultNowOnNull="false"
        v-model="delivery.end"
      />
      <v-textarea
        label="Memo"
        placeholder="Optional"
        v-model="delivery.memo"
        :rows="2"
        auto-grow
        outlined
      />
    </v-card-text>
    <v-card-actions class="d-flex justify-space-between">
      <div>
        <v-btn color="blue darken-1" text @click="save">
          {{ isEditMode ? "Update" : "Save" }}</v-btn
        >
        <v-btn class="ml-2" color="blue darken-1" text @click="cancel"
          >Cancel</v-btn
        >
      </div>
      <v-btn v-if="isEditMode" color="warning" @click="remove"> Delete </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import {
  deleteDeliveryScheduleById,
  createDeliverySchedule,
  updateDeliverySchedule,
} from "@/services/deliveryScheduleService.js";

export default {
  props: {
    product: {
      type: Object,
      default: "",
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    delivery: {
      id: null,
      productId: null,
      customerId: null,
      quantity: 0,
      start: "",
      end: "",
      memo: null,
    },
  }),
  computed: {
    ...mapGetters("company", ["customers"]),
    isEditMode() {
      return this.delivery && this.delivery.id;
    },
  },
  watch: {
    // Watch the value prop for changes
    value: {
      immediate: true, // This will run the function immediately upon loading
      handler(val) {
        this.delivery = { ...val };
      },
    },
  },
  created() {
    if (!this.delivery.productId) {
      this.delivery.productId = this.product.id;
    }
    this.fetchCustomers();
  },
  methods: {
    ...mapActions("company", ["fetchCustomers"]),

    remove() {
      deleteDeliveryScheduleById(this.delivery.id)
        .then(() => {
          const event = {
            productId: this.product.id,
            data: this.delivery,
            type: "deleted-delivery",
          };
          this.$emit("on-success", event);
        })
        .catch((error) => {
          console.error(error);
          this.addErrorMessage("Error deleting delivery schedule: ");
          this.$emit("error", error);
        });
    },
    cancel() {
      this.$emit("on-close");
    },
    save() {
      const cloneDelivery = { ...this.delivery };
      cloneDelivery.start = moment(this.delivery.start)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
      cloneDelivery.end = moment(this.delivery.end)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");

      if (cloneDelivery.id) {
        updateDeliverySchedule(cloneDelivery).then((resp) => {
          const event = {
            productId: this.product.id,
            data: this.delivery,
            type: "updated-delivery",
          };
          this.$emit("on-success", event);
        });
      } else {
        createDeliverySchedule(cloneDelivery)
          .then((resp) => {
            const newId = resp.data;
            this.delivery.id = newId;
            const event = {
              productId: this.product.id,
              data: this.delivery,
              type: "created-new-delivery",
            };
            this.$emit("on-success", event);
          })
          .catch((error) => {
            this.addErrorMessage(
              "Error creating new delivery schedule: ",
              error.data.message
            );
            this.$emit("error", error);
          });
      }
    },
  },
};
</script>
